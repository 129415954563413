import '../style/scss/page/index.scss';
import Swiper from 'swiper';
// import 'swiper/swiper-bundle.css'; // 需要降级，所以注释
import 'swiper/dist/css/swiper.min.css';
// animate.css v4版需要加前缀，所以加兼容模式的css
import 'animate.css/animate.compat.css';



var swiper = new Swiper('.swiper-container', {
    pagination: '.swiper-pagination',
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    paginationClickable: true,
    spaceBetween: 0,
    centeredSlides: true,
    autoplay: 4000,
    loop: true,
});

//安全保障悬浮
$('.safe li').hover(function() {
    $('.safe li').removeClass('act');
    $(this).addClass('act');
});

/*动画*/
$(".bring li").hover(function(){
    $(this).addClass("animated bounce");
},function(){
    $(this).removeClass("animated bounce");
})